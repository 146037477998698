
























import { Vue, Component, Prop } from 'nuxt-property-decorator';
import BreakpointMixin from '../../../mixins/BreakpointMixin';
import MediaHandler from '../../utilities/media/MediaHandler.vue';
import LinkManual from '../../utilities/links/LinkManual.vue';
import { BlokHeadingSection } from '~/types';

@Component({
  components: { LinkManual, MediaHandler }
})
export default class HeadingSection extends (BreakpointMixin) {
  @Prop() blok!: BlokHeadingSection;
}
